import JobErTable from "components/JobErTable";
import { useEffect, useMemo, useState } from "react";
import { getAnnunciConfartigianato } from "components/PortaleConfartigianato/utils_confartigianato/annunciConfartigianato";
import { useJobErController } from "context";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material";

function StoricoAnnunci(props) {
  const theme = useTheme();
  const [annunci, setAnnunci] = useState([]);
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (props.value === props.index) {
      if (authUser) {
        getAnnunciConfartigianato(setAnnunci, authUser.codConfartigianato, setPending);
      }
    }
  }, [props.value]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "statoLabel",
        header: "Stato",
        size: 150,
        Cell: ({ row }) => (
          <Chip
            label={row.original.statoLabel.toUpperCase()}
            variant="outlined"
            sx={{ color: row.original.statoColor, borderColor: row.original.statoColor }}
          />
        ),
      },
      {
        accessorKey: "tipoRicerca",
        header: "Modalità",
        size: 150,
        Cell: ({ row }) => {
          switch (row.original.tipoRicerca) {
            case "attiva":
              return (
                <Chip
                  label={row.original.tipoRicerca.toUpperCase()}
                  variant="outlined"
                  sx={{ color: theme.palette.success.main, borderColor: theme.palette.success.main }}
                />
              );
            case "passiva":
              return (
                <Chip
                  label={row.original.tipoRicerca.toUpperCase()}
                  variant="outlined"
                  sx={{ color: theme.palette.error.main, borderColor: theme.palette.error.main }}
                />
              );
            default:
              return <></>;
          }
        },
      },
      {
        accessorKey: "ragioneSocialePiva",
        header: "Ragione Sociale",
        size: 200,
      },
      {
        accessorKey: "partitaIva",
        header: "Partiva Iva",
        size: 150,
      },
      {
        accessorKey: "titoloAnnuncio",
        header: "Titolo Annuncio",
        size: 150,
      },
      {
        accessorKey: "posizioneLavorativaDescrizione",
        header: "Posizione Ricercata",
        size: 150,
      },
      {
        accessorKey: "countCandidature",
        header: "Candidati",
        size: 150,
      },
    ],
    []
  );

  return props.value === props.index ? <JobErTable data={annunci} columns={columns} pending={pending} /> : null;
}

export default StoricoAnnunci;
