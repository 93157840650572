import { getAuthHeader } from "assets/utils/auth-services";
import { JobErToast } from "components/JobErToast";
import colorsPalette1 from "assets/palette/colorsPalette1";

export function getAnnunciConfartigianato(setAnnunci, filiale, setPending) {
  setPending(true);

  let headers = getAuthHeader();
  headers["Content-Type"] = "application/json";

  fetch(process.env.REACT_APP_URL + "/confartigianato/getAnnunci/" + filiale, {
    headers: headers,
    method: "GET",
  })
    .then((res) => {
      if (!res.ok) {
        JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
        return null;
      }
      return res.json();
    })
    .then((result) => {
      for (const element of result) {
        switch (element.stato) {
          case 1:
            element.statoLabel = "ATTIVO";
            element.statoColor = colorsPalette1.success.main;
            break;
          case 2:
            element.statoLabel = "COMPLETATI";
            element.statoColor = colorsPalette1.primary.main;
            break;
          case 3:
            element.statoLabel = "IN APPROVAZIONE";
            element.statoColor = colorsPalette1.warning.main;
            break;
          case 4:
            element.statoLabel = "RIFIUTATO";
            element.statoColor = colorsPalette1.error.main;
            break;
          case 0:
            element.statoLabel = "ELIMINATO";
            element.statoColor = colorsPalette1.error.main;
            break;
          default:
            element.statoLabel = "";
            element.statoColor = "";
            break;
        }
      }

      setAnnunci(result);
      setPending(false);
    })
    .catch((err) => {
      JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento degli annunci" });
      setPending(false);
    });
}
