import React, { useState } from "react";
import { useJobErController, setAuthUser } from "context";
import JobErCard from "components/JobErCard";
import { Grid2 as Grid, TextField } from "@mui/material";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../../immagini/JOB_LOGO_CONFER_BLUE.svg";
import JobErButton from "components/JobErButton";
import { checkEmail, checkPassword, loginConfartigianato } from "components/PortaleConfartigianato/Login/utils/utils_login_confartigianato";
import { JobErToast } from "components/JobErToast";
import { useNavigate } from "react-router-dom";

function LoginConfartigianato(props) {
  const navigate = useNavigate();
  const [controller, dispatch] = useJobErController();

  const [utente, setUtente] = useState({ email: "", password: "" });
  const [errori, setErrori] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  const login = () => {
    if (errori?.email === "ok" && errori?.password === "ok" && utente?.email && utente?.password) {
      loginConfartigianato(utente)
        .then((user) => {
          setLoading(false);
          setAuthUser(dispatch, user);
          navigate("/Confartigianato/Home");
        })
        .catch(function (e) {
          setLoading(false);
          JobErToast.fire({ icon: "error", title: "Utente non riconosciuto!" });
        });
    }
  };

  return (
    <Grid sx={{ backgroundColor: "primary.main", height: "100vh" }} container direction="column" justifyContent="center" alignItems="center">
      <Grid size={{ xs: 12, md: 3 }}>
        <JobErCard
          child={
            <Grid container spacing={2}>
              <Grid size={12} textAlign="center">
                <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
              </Grid>
              <Grid size={12} textAlign="center">
                <TextField
                  fullWidth
                  label="Email"
                  size="small"
                  value={utente.email ? utente.email : ""}
                  onChange={(e) => setUtente({ ...utente, email: e.target.value })}
                  onBlur={(e) => checkEmail(e.target.value, setErrori, errori)}
                />
              </Grid>
              <Grid size={12} textAlign="center">
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  size="small"
                  value={utente.password ? utente.password : ""}
                  onChange={(e) => setUtente({ ...utente, password: e.target.value })}
                  onBlur={(e) => checkPassword(e.target.value, setErrori, errori)}
                />
              </Grid>
              <Grid size={12} textAlign="center">
                <JobErButton
                  size="large"
                  onClick={() => {
                    login();
                  }}
                  isLoading={loading}
                >
                  Accedi
                </JobErButton>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
}
export default LoginConfartigianato;
