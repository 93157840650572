import React from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import LayoutConfartigianato from "components/PortaleConfartigianato/LayoutConfartigianato";
import JobErCard from "components/JobErCard";

function HomeConfartigianato(props) {
  return (
    <LayoutConfartigianato
      child={
        <Grid container>
          <Grid size={12}>
            <JobErCard
              title="Home"
              child={
                <Grid container>
                  <Grid size={6} pt={2}>
                    <Typography variant="h6" color={"primary.main"} fontWeight="bold">
                      {"Totale aziende attive:"}
                    </Typography>
                  </Grid>
                  <Grid size={6} pt={2}>
                    <Typography variant="h6" color={"primary.main"} fontWeight="bold">
                      {" "}
                      {"Totale candidature:"}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      }
    />
  );
}
export default HomeConfartigianato;
